<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Teklif Bilgileri</span>
        </template>
        <validation-observer ref="simpleRules">
          <offer-form
            :submit-form="submitForm"
            :submit-status="submitStatus"
            :remove-item="removeItem"
            :add-items="getProductList"
          />
        </validation-observer>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <customer-info />
        <customer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-history :customer-id="dataItem.id_customers" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import OfferForm from '@/views/Admin/Offers/OfferForm.vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'Edit',
  components: {
    BTabs,
    BTab,
    OfferForm,
    CustomerInfo,
    CustomerInvoice,
    CustomerHistory,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    dataList() {
      return this.$store.getters['offerLines/productList']
    },
    saveData() {
      return this.$store.getters['offers/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.$router.push(`/app/offers/view/${val.id}`)
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },

  beforeCreate() {
    this.$store.commit('offers/RESET_DATA_ITEM')
    this.$store.commit('offerLines/RESET_PRODUCT_DATA')
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    getCustomerRelatedPerson(index) {
      this.$store.dispatch('customerRelatedPersons/getDataList', {
        where: {
          'customer_related_persons.id_customers': index,
        },
      })
    },
    getData() {
      this.$store.dispatch('offers/getDataItem', { id: this.$route.params.id, isUpdate: true })
        .then(response => {
          if (response.id_customers) {
            this.getCustomer(response.id_customers)
            this.getCurrency(response.id_currencies)
            this.getDataList(response.id_projects)
            this.getCustomerRelatedPerson(response.id_customers)
          }
        })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/getDataItem', id)
    },
    getDataList(projects) {
      this.$store.dispatch('offerLines/getProductList', {
        id_projects: projects,
        id_offers: this.$route.params.id,
      })
    },
    getCurrency(index) {
      this.$store.dispatch('currencies/getDataItem', index)
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // this.submitStatus = true
          let processError = false
          this.dataItem.offer_lines = []
          for (let i = 0; i < this.dataList.length; i += 1) {
            for (let l = 0; l < this.dataList[i].productList.length; l += 1) {
              if (!this.dataItem.id_currencies || !this.dataList[i].productList[l].id_units) {
                this.showToast({
                  variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: 'Lütfen "Birim" ve "Para Birimi" alanlarını doldurduğunuzdan emin olun.',
                })
                processError = true
                break
              }
              this.dataItem.offer_lines.push(this.dataList[i].productList[l])
            }
          }
          if (!processError) {
            this.$store.dispatch('offers/saveData', this.dataItem)
          }
        }
      })
    },
    removeItem(id, productType = null, index = null) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('offerLines/removeData', this.dataList[productType].productList[index].id)
          this.dataList[productType].productList.splice(index, 1)
          // this.$store.commit('offerLines/RESET_PRODUCT_DATA')
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          // this.getDataList(this.dataItem.id_projects)
        }
      })
    },
    getProductList() {
      this.$store.dispatch('offerLines/getProductList', {
        id_projects: this.dataItem.id_projects,
        id_offers: this.dataItem.id,
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
